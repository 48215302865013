import React, {useState, useEffect} from 'react'
import {LocalizedLink} from "src/components/commonComponents";
const TabsSectionBlock = ({contentTabsSection}) => {
  const [selected, setSelected] = useState('')
  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    if(!selected && !!contentTabsSection?.relationships?.fieldTabSectionItem?.length && contentTabsSection?.relationships?.fieldTabSectionItem[0]?.nid){
      setSelected(contentTabsSection?.relationships?.fieldTabSectionItem[0]?.nid);
      setSelectedItems(contentTabsSection?.relationships?.fieldTabSectionItem[0]);
    }
  },[])
  const onChange = (entityId) => {
    setSelected(entityId)
    setSelectedItems(contentTabsSection?.relationships?.fieldTabSectionItem.filter(x=> x.nid == entityId)[0])
  }
  return (
    <div className="items-content">
      <div className="tab-box -lg:tw-overflow-y-scroll -lg:tw-mt-[52px] tw-mb-8 lg:tw-mb-[33px] tw-overflow-x-scroll xsm:tw-overflow-x-hidden">
        <ul className='tw-w-[210%] tw-text-left  md:tw-text-center md:!tw-w-auto xsm:tw-w-[230%]'>
          {
            contentTabsSection?.relationships?.fieldTabSectionItem?.map((entity, index) => {
              return(
                <li className='tw-inline-block tw-text-[18px] tw-font-bold tw-leading-6 tw-pt-0 tw-pb-[21px] tw-px-[30px] tw-border-b-4 tw-border-b-accruent_input_background tw-border-solid first:tw-pl-0' key={index}>
                  <a 
                  className={` hover:after:tw-bg-[#001c71] tw-pt-0 tw-pb-[22px] tw-px-2.5 tw-opacity-80 tw-text-[18px] tw-text-accruent-base-color hover:tw-opacity-100 hover:tw-relative hover:after:tw-w-full hover:after:tw-h-1 hover:after:tw-content-[''] hover:after:tw-absolute hover:after:tw-rounded hover:after:tw-left-0 hover:after:tw--bottom-1 ${entity?.nid == selected ? "active tw-relative !tw-opacity-100 after:tw-w-full after:tw-h-1 after:tw-content-[''] after:tw-absolute after:tw-rounded after:tw-left-0 after:tw--bottom-1 after:tw-bg-[#001c71]": ""}`}
                  onClick={() => onChange(entity?.nid)}
                  >{entity?.title}</a>
                </li>
              )
             
            })
          }
        </ul>
      </div>
      {selected && selectedItems?.relationships &&
        <div>
           <div
              className="items-paragraph tw-text-center tw-text-subtitleLgFont tw-text-accruent-base-color tw-mb-10 dangerously-html-handle"
              dangerouslySetInnerHTML={{ __html: selectedItems?.relationships?.body?.value }}
            />
             <div className="tw-columns is-multiline about-ems tw-p-0 md:tw-p-[inherit]">
              {!!selectedItems?.relationships?.fieldTabItem.length &&
                selectedItems?.relationships?.fieldTabItem.map((entityItem, index) => {
                  return(
                    <div key={index} className="tw-column tw-p-[15px] tw-pb-0 lg:tw-w-[33.33%] lg:tw-flex-none md:tw-w-[50%] tw-w-full">
                      <div className="cards tw-relative tw-h-[110%] tw-pt-9 tw-pb-6 tw-px-[22px] -md:tw-px-[22px] -md:tw-py-[26px] tw-bg-[#eee]">
                        <h3 className='title-4 tw-pb-2'>{entityItem?.title}</h3>
                        <div
                          dangerouslySetInnerHTML={{ __html: entityItem?.body?.value }}
                          className="dangerously-html-handle"
                        />
                        {entityItem?.fieldCtaUrl &&
                          <LocalizedLink
                          to={entityItem?.fieldCtaUrl?.url}
                          className=" -md:tw-static -md:tw-mb-0  -md:tw-mt-[17px] hover:tw-text-white tw-font-Poppins tw-text-4 tw-font-bold tw-h-auto tw-absolute tw-bg-link-color tw-tracking-[1.42px] tw-leading-4 tw-text-accruent_white tw-border tw-cursor-pointer tw-justify-center tw-text-center tw-align-top tw-shadow-none tw-inline-flex tw-items-center tw-mt-[26px] tw-mb-3 tw-px-6 tw-py-3.5 tw-rounded-xl tw-border-transparent tw-border-solid tw-bottom-5"
                          >
                            {entityItem?.fieldCtaUrl?.title}
                          </LocalizedLink>
                        }
                      </div>
                    </div>
                  )
                })
                }
            </div>
          </div>
      }
    </div>
  );
};
export default TabsSectionBlock;